// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Collapsible-module__title--dHkQn:hover{cursor:pointer}.Collapsible-module__trigger--mjjU8{display:flex;align-items:center;cursor:pointer;gap:var(--eds-space-8)}`, "",{"version":3,"sources":["webpack://./src/components/Collapsible/Collapsible.module.styl"],"names":[],"mappings":"AAAA,wCACE,cAAO,CAET,oCACE,YAAQ,CACR,kBAAY,CACZ,cAAO,CACP,sBAAI","sourcesContent":[".title:hover\n  cursor pointer\n\n.trigger\n  display flex\n  align-items center\n  cursor pointer\n  gap var(--eds-space-8)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Collapsible-module__title--dHkQn`,
	"trigger": `Collapsible-module__trigger--mjjU8`
};
export default ___CSS_LOADER_EXPORT___;
