import styles from './Collapsible.module.styl'
import { Icon } from '@nike/eds'
import ReactCollapsible from 'react-collapsible'

interface CollapsibleProps {
  className: string
  innerClassName: string
  title: JSX.Element
  transitionTime?: number
  children: JSX.Element | JSX.Element[]
  isOpen?: boolean
  id?: string
}

interface TriggerProps {
  isOpen?: boolean
  title: JSX.Element
}

const Trigger = ({ isOpen = false, title }: TriggerProps) => (
  <div className={styles.trigger}>
    <Icon name={isOpen ? 'CaretUp' : 'CaretDown'} size='s' />
    <div className={styles.title}>{title}</div>
  </div>
)

export const Collapsible = ({
  className,
  innerClassName,
  title,
  transitionTime = 50,
  children,
  isOpen = false,
  id = 'widgetCard',
}: CollapsibleProps): JSX.Element => (
  <ReactCollapsible
    className={className}
    openedClassName={className}
    transitionTime={transitionTime}
    open={isOpen}
    triggerWhenOpen={<Trigger title={title} isOpen />}
    trigger={<Trigger title={title} isOpen={undefined} />}
    contentElementId={`collapsibleContent-${id}`}
    containerElementProps={{ id: `collapsibleContainer-${id}` }}
    triggerElementProps={{ id: `collapsibleTrigger-${id}` }}
  >
    <div className={`${styles.collapsibleInner} ${innerClassName}`}>{children}</div>
  </ReactCollapsible>
)
