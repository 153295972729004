import styles from '../../widget.module.styl'
import { IconButton, Menu } from '@nike/eds'
import { useMemo } from 'react'

export enum MenuType {
  EXPAND = 'expand',
  FAVORITE = 'favorite',
  LINK = 'link',
  REFRESH = 'refresh',
  REMOVE = 'remove',
  RESIZE = 'resize',
  SHARE = 'share',
  EDIT_QUERY = 'editQuery',
  BASIC = 'basic',
}

interface WidgetMenuProps {
  isMenuOpen: boolean
  toggleMenu: () => void
  bodySlotContent?: () => (JSX.Element | undefined)[]
}

export const WidgetMenu = ({
  isMenuOpen,
  toggleMenu,
  bodySlotContent,
}: WidgetMenuProps): JSX.Element => {
  const bodySlotContentItems = useMemo(() => bodySlotContent?.(), [bodySlotContent])

  return (
    <Menu
      className={styles.widgetMenu}
      onClickOutside={toggleMenu}
      isOpen={isMenuOpen}
      placement='bottom-end'
      aria-label='Widget menu'
      data-testid='widget-menu-body'
      bodySlot={!!bodySlotContentItems?.length && bodySlotContentItems}
    >
      <IconButton
        label='Menu'
        size='small'
        variant='ghost'
        disableRipple
        onClick={toggleMenu}
        icon='EllipsisVertical'
        data-testid='widget-menu-btn'
      />
    </Menu>
  )
}
