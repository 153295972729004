import chartStyles from '../charts.module.styl'
import type { DataItem } from '../types'
import styles from './bigNumberChart.module.styl'
import { Link } from '@nike/eds'
import classnames from 'classnames'

interface BigNumberChartProps {
  data?: DataItem[]
  isResponsive: boolean
}

export const BigNumberChart = ({
  data,
  isResponsive = false,
}: BigNumberChartProps): JSX.Element => (
  <div
    className={classnames(styles.bigNumberChart, chartStyles.scrollable, {
      [styles.responsive]: isResponsive,
    })}
  >
    <div className={styles.dataWrapper}>
      {data?.map((entry, index) => (
        <div className={styles.content} key={index}>
          {entry.drillDownUrl ? (
            <Link target='_blank' href={String(entry.drillDownUrl)} className={styles.value}>
              {entry?.precedingUnit}
              {entry.number} {entry?.unit}
            </Link>
          ) : (
            <p className={styles.value}>
              {entry?.precedingUnit}
              {entry.number} {entry?.unit}
            </p>
          )}
          <p className={styles.info}>{entry.additionalInformation}</p>
        </div>
      ))}
    </div>
  </div>
)
