import { AxesConfig, ChartType } from './components/Charts/types'

export interface Option<T> {
  value: T
  label: string
}

export type WidgetLifecycle = {
  state: WidgetLifecycleState
  message?: string | null
}

export interface Widget {
  chartType?: ChartType | null
  colorPalette?: ColorPalette | null
  dataQuery?: string | null
  dataSourceURL: string
  description: string
  examples?: (WidgetExample | null)[] | null
  id?: string | null
  isFavorite?: boolean | null
  lifecycle: WidgetLifecycle
  maintainerEmail: string
  maintainerSlack: string
  moreInfoURL?: string | null
  name: string
  refreshFrequency: number
  scope: WidgetScope
  size?: WidgetSize | null
  tags: (string | null)[]
  techSolutionIds?: (string | null)[] | null
  type: DataSourceType
  updatedBy?: string | null
  updatedOn?: number | null
  version?: string | null
  timezoneSupport?: boolean | null
  showChartLegend?: boolean | null
  queryParamsSupport?: boolean | null
  supportedParams?: SupportedParam[] | null
  customWidgetId?: string | null
  queryParams?: WidgetQueryParams[] | null
  withBrush?: boolean | null
  axesConfig?: AxesConfig | null
}

export type WidgetQueryParams = {
  parameter: string
  value: string
}

export type SupportedParam = {
  parameter: string
  sampleValue?: string | null
  defaultValue?: string | null
  isRequired?: boolean | null
}

export type WidgetExample = {
  data: string
  description: string
  name: string
}

export enum WidgetScope {
  SYSTEMIC = 'systemic',
  USER = 'user',
}

export enum WidgetSize {
  BIGSQUARE = 'bigSquare',
  HORIZONTALRECT = 'horizontalRect',
  SMALLSQUARE = 'smallSquare',
  VERTICALRECT = 'verticalRect',
}

export enum WidgetLifecycleState {
  DEPRECATED = 'deprecated',
  PENDING = 'pending',
  PRERELEASE = 'prerelease',
  RELEASE = 'release',
  RETIRED = 'retired',
}

export enum ColorPalette {
  CATEGORICAL = 'categorical',
  SEQUENTIAL = 'sequential',
}

export enum DataSourceType {
  API = 'api',
  GITHUB = 'github',
  GRAPHQL = 'graphql',
  CX = 'cx',
  SIGNALFX = 'signalFx',
}

// types copied from PC
type SnackStatus = 'info' | 'success' | 'warning' | 'error' | 'neutral'

export interface SnackOptions {
  id?: number | string
  message: string
  onDismiss?: (id: string) => void
  status?: SnackStatus
  autoDismissDuration?: number
}
