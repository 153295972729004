import styles from './calendarChart.module.styl'
import { IconButton, Text } from '@nike/eds'

interface PaginationProps {
  currentPage: number
  totalPages: number
  handleNext: () => void
  handlePrev: () => void
}

export const Pagination = ({
  currentPage,
  totalPages,
  handleNext,
  handlePrev,
}: PaginationProps) => (
  <div className={styles.pagination}>
    <Text font='subtitle-2'>
      {currentPage + 1} of {totalPages}
    </Text>
    <IconButton
      label='Previous page'
      onClick={handlePrev}
      disabled={currentPage === 0}
      icon='CaretLeft'
      size='small'
      variant='secondary'
    />
    <IconButton
      label='Next page'
      onClick={handleNext}
      disabled={currentPage === totalPages - 1}
      icon='CaretRight'
      size='small'
      variant='secondary'
    />
  </div>
)
