import { Pagination } from './Pagination'
import styles from './calendarChart.module.styl'
import { Event } from './types'
import { formatEventTime } from './utils'
import { Icon, Link, Text } from '@nike/eds'
import { useState } from 'react'

interface EventsListProps {
  events: Event[]
}

const PAGE_SIZE = 3

export const EventsList = ({ events }: EventsListProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const pagesCount = Math.ceil(events.length / PAGE_SIZE)
  const startIndex = currentPage * PAGE_SIZE

  const handleNext = () => setCurrentPage((prevState) => prevState + 1)
  const handlePrev = () => setCurrentPage((prevState) => prevState - 1)

  const eventsToDisplay = events.slice(startIndex, startIndex + PAGE_SIZE)

  if (events.length) {
    return (
      <>
        <div className={styles.eventsList}>
          {eventsToDisplay.map((event) => (
            <div className={styles.eventItem} key={event.name}>
              <div className={styles.eventHeader}>
                <div className={styles.eventHeaderDate}>
                  <Text font='body-3'>
                    {event.start.toLocaleDateString('default', { month: 'short' })}
                  </Text>
                  <Text font='title-4' color='brand-orange'>
                    {event.start.getDate()}
                  </Text>
                </div>
                <Text font='title-6' className='eds-flex eds-flex--align-items-center'>
                  {event.name}
                </Text>
              </div>
              <Text font='body-3'>{formatEventTime(event.start, event.end)}</Text>
              {event.physicalLocation && (
                <Text font='body-3' color='secondary'>
                  {event.physicalLocation}
                </Text>
              )}
              {event?.virtualLocation && (
                <Link font='body-3' href={event.virtualLocation} target='_blank'>
                  Virtual Meeting URL
                </Link>
              )}
              {event?.drillDownUrl && (
                <Link font='body-3' href={event.drillDownUrl} target='_blank'>
                  More info
                </Link>
              )}
            </div>
          ))}
        </div>
        {pagesCount > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={pagesCount}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        )}
      </>
    )
  }

  return (
    <div className={styles.noEventsMessage}>
      <Icon name='BrightnessNone' size='m' color='grey' />
      <Text font='body-3'>There are no events on this day</Text>
    </div>
  )
}
