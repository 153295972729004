import { DataItem } from '../types'
import { DayCell } from './DayCell'
import styles from './calendarChart.module.styl'
import { RawEventData } from './types'
import classnames from 'classnames'
import { useState, MouseEvent } from 'react'
import { DayPicker, DayProps } from 'react-day-picker'
import 'react-day-picker/src/style.css'

interface CalendarChartProps {
  data?: DataItem[]
  isExpanded: boolean
}

const isEvent = (item: DataItem): item is RawEventData =>
  !!item.name && typeof item.start === 'string' && typeof item.end === 'string'

export const CalendarChart = ({ data, isExpanded }: CalendarChartProps) => {
  const [selected, setSelected] = useState<Date>()

  const resetSelection = (event: MouseEvent<HTMLElement, MouseEvent>) => {
    const { classList } = event.target as HTMLElement
    if (!classList.contains('rdp-day_button')) {
      setSelected(undefined)
    }
  }

  const mappedEvents =
    data?.filter(isEvent).map((item) => ({
      ...item,
      start: new Date(item.start),
      end: new Date(item.end),
    })) || []

  return (
    <div className={classnames(styles.calendarWrapper, { [styles.expanded]: isExpanded })}>
      <DayPicker
        mode='single'
        selected={selected}
        onSelect={setSelected}
        components={{
          Day: (props: DayProps) => (
            <DayCell
              {...props}
              isSelected={selected?.toDateString() === props.day.date.toDateString()}
              events={mappedEvents.filter(
                (event) => event.start.toDateString() === props.day.date.toDateString()
              )}
              resetSelection={resetSelection}
              isExpanded={isExpanded}
            />
          ),
        }}
        modifiers={{
          events: mappedEvents.map((event) => event.start),
        }}
        modifiersClassNames={{
          events: styles.event,
          selected: styles.selected,
          today: styles.today,
        }}
      />
    </div>
  )
}
