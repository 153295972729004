import { labels } from '../../constants'
import { SnackOptions } from '../../types'
import { getWidgetUrl } from '../../utils/getWidgetUrl'
import styles from '../../widget.module.styl'
import { ResizeWidget } from './ResizeWidget'
import { MenuType, WidgetMenu } from './WidgetMenu'
import { MenuItem } from '@nike/eds'
import classnames from 'classnames'
import { Fragment } from 'react'

type AdditionalPropType = string | number | boolean | JSX.Element | object | null | undefined

export interface WidgetMenuItemConfig {
  type: MenuType
  classNames?: string
  withDivider?: boolean
  onClick?: (...args: any) => void
  additionalProps?: { [key: string]: AdditionalPropType }
  content?: string
  contextualComponentProps?: { [key: string]: AdditionalPropType } // props for component rendered on menu item click
}

interface WidgetMenuWrapperProps {
  config: WidgetMenuItemConfig[]
  isMenuOpen: boolean
  id: string
  toggleMenu: () => void
  createSnack?: (options: SnackOptions) => void
}

export const WidgetMenuResolver = ({
  isMenuOpen,
  toggleMenu,
  config = [],
  id,
  createSnack,
}: WidgetMenuWrapperProps) => {
  const supportedMenuItems = {
    [MenuType.BASIC]: ({ onClick, content, withDivider }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
        className={withDivider ? styles.withDivider : ''}
      >
        {content}
      </MenuItem>
    ),
    [MenuType.SHARE]: ({ withDivider }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={async () => {
          const widgetUrl = getWidgetUrl(id || '')
          await navigator.clipboard.writeText(widgetUrl)
          createSnack?.({
            id: 'widget-link-copied',
            status: 'success',
            message: 'Link copied to clipboard',
          })
          toggleMenu()
        }}
        className={withDivider ? styles.withDivider : ''}
      >
        {labels.menu.share}
      </MenuItem>
    ),
    [MenuType.LINK]: ({ additionalProps, content, withDivider }: WidgetMenuItemConfig) => (
      <li>
        <a
          className={classnames('eds-menu-item', styles.link, {
            [styles.withDivider]: withDivider,
          })}
          target='_blank'
          {...additionalProps}
        >
          {content}
        </a>
      </li>
    ),
    [MenuType.EXPAND]: ({ onClick, withDivider }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
        className={withDivider ? styles.withDivider : ''}
      >
        {labels.menu.expand}
      </MenuItem>
    ),
    [MenuType.REFRESH]: ({ onClick, withDivider }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
        className={withDivider ? styles.withDivider : ''}
      >
        {labels.menu.refresh}
      </MenuItem>
    ),
    [MenuType.RESIZE]: ({ onClick, additionalProps, withDivider }: WidgetMenuItemConfig) => (
      <ResizeWidget
        handleResizeWidget={onClick}
        toggleMenu={toggleMenu}
        id={id}
        {...additionalProps}
        classNames={withDivider ? styles.withDivider : ''}
      />
    ),
    [MenuType.REMOVE]: ({
      classNames,
      onClick,
      additionalProps,
      withDivider,
    }: WidgetMenuItemConfig) => (
      <MenuItem
        className={classnames(classNames, {
          [styles.withDivider]: withDivider,
        })}
        onClick={onClick}
        {...additionalProps}
      >
        {labels.menu.remove}
      </MenuItem>
    ),
    [MenuType.FAVORITE]: ({
      classNames,
      onClick,
      additionalProps,
      content,
      withDivider,
    }: WidgetMenuItemConfig) => (
      <MenuItem
        className={classnames(classNames, {
          [styles.withDivider]: withDivider,
        })}
        onClick={onClick}
        {...additionalProps}
      >
        {content}
      </MenuItem>
    ),
    [MenuType.EDIT_QUERY]: ({
      classNames,
      onClick,
      additionalProps,
      withDivider,
    }: WidgetMenuItemConfig) => (
      <MenuItem
        className={classnames(classNames, {
          [styles.withDivider]: withDivider,
        })}
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
        {...additionalProps}
      >
        {labels.menu.editQuery}
      </MenuItem>
    ),
  }

  const bodySlotContent = () =>
    config.map(
      ({ type, ...rest }: WidgetMenuItemConfig, index: number) =>
        type && <Fragment key={index}>{supportedMenuItems[type]({ type, ...rest })}</Fragment>
    )

  return (
    <WidgetMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} bodySlotContent={bodySlotContent} />
  )
}
